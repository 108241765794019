import React, { Component } from 'react';

import {
  MainTitle,
  MainTitleDescription
} from '../constants/TitlesAndDescriptions';
import Section from '../components/Section';
import Contact from '../containers/Contact';
import Footer from '../containers/Footer';
import NavBar from '../components/NavBar';
import About from '../containers/About';
import Video from '../containers/Video';
import { col_one } from '../constants/sectionAttributes';

export default class Home extends Component {
  render() {
    const main_col = {
      ...col_one,
      className: 'mt-sm-0 mt-md-5 mb-2 px-sm-3 px-md-0 text-center col'
    }

    return (
      <div id="home" >
      <NavBar />
       <Section
            main
            col_one={main_col}
            heading={MainTitle}
            subHeading={MainTitleDescription} />
        <Video/>
        <About />
        <Contact />
        <Footer />
      </div>
    )
  }
}
