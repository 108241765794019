import React, { Component } from 'react';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import Logo from './Logo';


export default class NavBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.state = {
            isOpen: false,
            scrollTop: true,
            scrolling: false,
            logoColor: '#0051b5'
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    handleScroll(event) {
        if (window.scrollY === 0 && this.state.scrolling === true) {
            this.setState({ scrolling: false, logoColor: '#0051b5', scrollTop: true });
        }
        else if (window.scrollY >= 40 && this.state.scrolling !== true) {
            this.setState({ scrolling: true, logoColor: '#fff', scrollTop: false });
        }
    }
    toggle() {
        if (window.screen.availWidth < 768) {
            this.setState((prevState) => ({
                isOpen: !this.state.isOpen,
                logoColor: (prevState.isOpen && prevState.scrollTop) ? '#0051b5' : '#ffffff'
            })
            );
        }
    }


    render() {
        let navTextColor = this.state.scrolling || this.state.isOpen ? 
        { ...styles.nav_link, color: '#fff' } : styles.nav_link;
        return (
            <Navbar
                className={`${this.state.scrolling || this.state.isOpen ? 'sticky-top-custom' : 'sticky-top'}`}
                light expand="md" >
                <div className='container'>
                    <NavbarBrand href="/">
                        <Logo fill={this.state.logoColor} stroke={this.state.logoColor} />
                    </NavbarBrand>
                    <NavbarToggler style={{ color: '#0051b5' }} onClick={this.toggle} />
                    <Collapse style={{ color: '#0051b5' }} isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className=" mx-auto mx-md-0">
                                <NavLink style={navTextColor}
                                    onClick={this.toggle}
                                    href="#home">HOME</NavLink>
                            </NavItem>
                            <NavItem className=" mx-auto mx-md-0">
                                <NavLink style={navTextColor}
                                    onClick={this.toggle}
                                    href="#about">ABOUT US</NavLink>
                            </NavItem>
                            <NavItem className=" mx-auto mx-md-0">
                                <NavLink style={navTextColor}
                                    onClick={this.toggle}
                                    href="#contact">CONTACT US</NavLink>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        )
    }
}

const styles = {
    nav_link: {
        fontFamily: 'Montserrat SemiBold',
        fontSize: '1rem',
        color: '#0051B5'
    }
}