import React from 'react'
import { Col, Button } from 'reactstrap';

const FooterLink = (props) => {
    return (
        <Col
            sm={props.colOptions && props.colOptions.sm}
            md={props.colOptions && props.colOptions.md}
            className="d-flex justify-content-center my-2">
            <Button style={{
                color: '#fff',
                fontSize: '0.875rem',
                fontFamily: 'Open Sans SemiBold',
            }} color="link">{props.label}</Button>
        </Col>
    )
};

export default FooterLink;
