import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';

const Section = ({ col_one, col_two, main, heading, subHeading }) => {
    return (
        <Container>
            <Row>
                {
                    main && <Col
                    className={`${col_one.className}`}>
                     <h1 className="main-title">{heading}</h1>
                     <h5 className={`about-subtitles-text text-center`}>{subHeading}</h5>
                    </Col>
                }
                {!main && col_one && <Col
                    sm={!main && col_one.sm}
                    md={col_one.md}
                    className={`my-5 my-md-5 px-0 text-center ${col_one.className}`}
                >
                    <Container fluid>
                        <h3 className="about-subtitles">{heading}</h3>
                        <h5 className="about-subtitles-text">{subHeading}</h5>
                    </Container>
                </Col>}
                {col_two && <Col
                    sm={col_two.sm}
                    md={col_two.md}
                    className={`d-flex justify-content-center align-items-center px-0`}
                >
                    <img
                        className={`${col_two.imgClassName} ${main && 'px-3'}`}
                        src={`${col_two.imgPath}`}
                        alt={col_two.imgAlt}
                    />
                </Col>}
            </Row>
        </Container>
    )
}

Section.propTypes = {
    main: PropTypes.bool,
    col_one: PropTypes.object,
    col_two: PropTypes.object,
    heading: PropTypes.string,
    subHeading: PropTypes.string
};

export default Section;