import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import Home from './containers/Home';


library.add(fab);

class App extends Component {
  render() {
    return (
      <div>
        
        <Home />
        
      </div>
    );
  }
}

export default App;
