import React, { Component, Fragment } from 'react'

import VideoPlayer from '../components/VideoPlayer';
import TitleOfSections from '../components/TitleOfSections';

import { BookingSoftware, VideoDescription } from '../constants/TitlesAndDescriptions';

export default class Video extends Component {
  render() {
    return (
      <Fragment>
        <VideoPlayer/>
      </Fragment>
    )
  }
}
