import React from 'react'

const Logo = (props) => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" width="102.679" height="34.969" viewBox="0 0 102.679 34.969">
                <g transform="translate(-79.5 -28.484)">
                    <g transform="translate(80 29)">
                        <path d="M114.446,33.682H101.564L115.658,6.369a1.407,1.407,0,0,0,.182-.718,1.274,1.274,0,0,0-1.273-1.137H95.623c-.97,0-5.368.267-5.323,1.911V35.054a1.262,1.262,0,0,0,.97,1.256,1.22,1.22,0,0,0,1.455-1.227V8.818C92.6,6.673,97.017,7,97.957,7h11.821c1.273,0,3.183,0,1.516,2.573L98.745,33.861a1.67,1.67,0,0,0-.212.538h0a2.564,2.564,0,0,0-.03.688,1.3,1.3,0,0,0,1.273,1.107h14.7a1.257,1.257,0,0,0-.03-2.513Z"
                            transform="translate(-59.335 -3.223)" fill={props.fill} stroke={props.stroke} strokeWidth="1" />
                        <path d="M175.249,36.281h-1.425a.412.412,0,0,1-.424-.419V5.019a.412.412,0,0,1,.424-.419h1.425a.412.412,0,0,1,.424.419V35.892A.406.406,0,0,1,175.249,36.281Z"
                            transform="translate(-111.481 -3.223)" fill={props.fill} stroke={props.stroke} strokeWidth="1" />
                        <g transform="translate(70.876 1.646)">
                            <path d="M241.727,40.721l-1.546,1.406a3.331,3.331,0,0,1,.152.957,3.5,3.5,0,1,1-3.516-3.44,3.347,3.347,0,0,1,1.455.329c.455-.389,1.273-1.017,1.637-1.316a5.572,5.572,0,0,0-3.122-.957,5.415,5.415,0,1,0,5.486,5.415A4.812,4.812,0,0,0,241.727,40.721Z"
                                transform="translate(-221.055 -28.067)" fill={props.fill} stroke={props.stroke} strokeWidth="1" />
                            <path d="M225.567,23.808A13.036,13.036,0,0,1,199.925,20.7a13.011,13.011,0,0,1,25.945-1.227c1,.03,2.364.12,2.394.12A15.411,15.411,0,0,0,197.5,20.7a15.419,15.419,0,0,0,30.492,3.021Z"
                                transform="translate(-197.5 -5.5)" fill={props.fill} stroke={props.stroke} strokeWidth="1" />
                        </g><path d="M21.308,13.28c-.091-.06-.242-.179-.242-.179L6.911,1.194A7.687,7.687,0,0,0,6.092.656C3.273-1.2.879,1.314.364,2.63A5.573,5.573,0,0,0,0,4.305V29.4a3.867,3.867,0,0,0,.212,1.316,4.683,4.683,0,0,0,2.334,2.842c2.243,1.2,4.577-.9,5-1.256.061-.06.121-.09.182-.15,1.031-.778,8.3-6.282,13.7-10.56C26.43,17.588,22.1,13.819,21.308,13.28Zm-1.061,5.863C18.125,20.729,5.365,30.512,5.365,30.512s-2.516,2.333-2.7-1.675-.03-23.3-.03-23.3-.152-2.184.818-2.692,2,.628,3.3,1.735S20.186,15.853,20.186,15.853,22.369,17.558,20.247,19.144Z"
                            transform="translate(0 0.003)" fill={props.fill} stroke={props.stroke} strokeWidth="1" />
                    </g>
                </g>
            </svg>
    )
}

export default Logo
