/* Titles */

export const AboutTile = `About Us`;
export const BookingSoftware = `An Online Booking Software`;
export const ContactUs = `Contact Us`;
export const DizioBookings = `Dizio Bookings`;
export const DizioLoadsManager = `Dizio loads manager`;
export const DizioReports = `Dizio Reports`;
export const DizioWaiver = `Dizio Waiver`;
export const MainTitle = `DIZIO DROPZONE ASSISTANT`;

/* Descriptions */

export const AboutDescription =  `Dizio dropzone assistant offer an easy to use integrated solution for managing all aspects of a
dropzone. Easily manage your waivers, tandem/aff bookings, loads manifesting, 
reports generation, payment collections and other integrations.`;

export const VideoDescription = `Dizio is an all in one solution for skydiving operators they are facing today it provides
Manifesting control center, Jumper Manifesting App, Online Ticket Booking,
Waiver and Private Jumper App`;

export const DizioBookingsDescription = `Dizio bookings offers an easy to use platform for 
prospective tandem students and aff students to 
book their rides. Easily set up your time slots, and 
price schedules, view your bookings, review your 
waivers, and record your jumps for easy accounting.`;

export const DizioLoadsManagerDescription = `Easily create loads and easily manage tandem assignments,
aff assignments, video assignments and licensed jumper manifesting.  Jumpers can easily
manifest onto a load and dizio will record the jumps after completion for logging and reports generation.`;

export const DizioReportsDescription = `With Dizio reports you can easily view your daily 
sales, invoice over date ranges, staff statements, 
and jumper logs.`;

export const DizioWaiverDescription = `Dizio offers an electronic waiver solution for tandem students and your regular jumpers.
Students can access the waiver when they are on site, sign them electronically and submit them. No
more cumbersome paperwork.
`;

export const MainTitleDescription = `All in one solution for your Dropzone Management`;