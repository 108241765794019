import React from 'react';
import { Container, Col, Row } from 'reactstrap';

import IconButton from '../components/IconButton';
import FooterLink from '../components/FooterLink';
import { FooterColOptions_one, FooterColOptions_two } from '../constants/sectionAttributes';

const Footer = () => {
    return (
        <div>
            <Container fluid style={{ backgroundColor: '#001833' }}>
                <Container>
                    <Row className="d-flex" style={{ paddingTop: '2.875rem', justifyContent: 'space-around' }}>
                        <FooterLink colOptions={FooterColOptions_one} label="CONTACT INFO" />
                        <FooterLink colOptions={FooterColOptions_two} label="ADDRESS" />
                        <FooterLink colOptions={FooterColOptions_two} label="PHONE NUMBER" />
                        <FooterLink colOptions={FooterColOptions_two} label="EMAIL" />
                        <FooterLink colOptions={FooterColOptions_one} label="SITE INFO" />
                        <FooterLink colOptions={FooterColOptions_two} label="TERMS OF USE" />
                        <FooterLink colOptions={FooterColOptions_two} label="PRIVACY POLICY" />
                    </Row>
                    <Row>
                        <Col sm={{ size: 12 }}
                            className='d-flex justify-content-center'>
                            <IconButton iconOptions={{ iconType: 'fab', iconName: 'facebook-f' }} />
                            <IconButton iconOptions={{ iconType: 'fab', iconName: 'twitter' }} />
                            <IconButton iconOptions={{ iconType: 'fab', iconName: 'linkedin' }} />
                            <IconButton iconOptions={{ iconType: 'fab', iconName: 'youtube-square' }} />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid>
                <Row>
                    <Col
                        className='d-flex justify-content-center'
                        style={{
                            height: '3.5rem',
                            backgroundColor: '#001227',
                            alignItems: 'center'
                        }}>
                        <p
                            style={{
                                fontFamily: 'Open Sans Light',
                                fontWeight: '300',
                                color: '#fff',
                                fontSize: '0.75rem',
                                marginBottom: 0
                            }}>
                            Copyright &copy; 2019 Merging Seas Company All rights reserved
                        </p>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default Footer
