/* Common Options for Normal Section */
export const col_one = {
    sm: { size: 12, order: 2 },
    md: { size: 6, order: 1 },
    order:undefined,
    className: 'text-sm-center text-md-left'
};
export const col_two = {
    sm: { size: 12, order: 1 },
    md: { size: 6, order: 2 },
    imgClassName: 'img-fluid',
    imgPath: '',
    imgAlt: ''
};

/*Common Options for Reverse Section */
export const col_one_reverse = {
    sm: { size: 12, order: 1 },
    md: { size: 6, order: 2 },
    className: 'text-sm-center text-md-left'
};
export const col_two_reverse = {
    sm: { size: 12, order: 2 },
    md: { size: 6, order: 1 },
    imgClassName: 'img-fluid',
    imgPath: '',
    imgAlt: ''
};
 /* Footer Col Options */
 export const FooterColOptions_one = { 
    sm: { size: 12 }, 
    md: { size: 1 } 
};
export const FooterColOptions_two = { 
    sm: { size: 12 }, 
    md: { size: 2 } 
};

