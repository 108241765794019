import React from 'react';
import { Button, Form, FormGroup, Label, Input, Container, Col, Row } from 'reactstrap';

const ContactForm = () => {
    return (
        <Container fluid>
            <Row className='d-flex justify-content-center'>
                <Col md={{ size: 10 }}>
                    <Form>
                        <h2 style={{
                            fontFamily: 'Montserrat',
                            fontSize: '1.5rem',
                            width: '100%',
                            textAlign: 'center',
                            paddingBottom: '3.75rem'
                        }}>Contact Us</h2>
                        <FormGroup>
                            <Label
                                className="contact-label"
                                for="Dropzone">Dropzone</Label>
                            <Input
                                style={{ height: '3.75rem' }}
                                type="text" name="Dropzone" id="Dropzone" />
                        </FormGroup>
                        <FormGroup>
                            <Label
                                className="contact-label"
                                for="Email">Email</Label>
                            <Input
                                style={{ height: '3.75rem' }}
                                type="email" name="email" id="Email" />
                        </FormGroup>
                        <FormGroup>
                            <Label
                                className="contact-label"
                                for="Message">Message</Label>
                            <Input
                                style={{ height: '16.625rem' }}
                                type="textarea" name="Message" id="Message" />
                        </FormGroup>
                        <Button block
                            className="submitButton"
                            style={{ fontFamily: 'Montserrat SemiBold' }}
                        >SUBMIT</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactForm;
