import React, { Component } from 'react'

import {
  AboutTile,
  DizioBookings,
  DizioLoadsManager,
  DizioReports,
  DizioWaiver,
  AboutDescription,
  DizioWaiverDescription,
  DizioReportsDescription,
  DizioBookingsDescription,
  DizioLoadsManagerDescription,
} from '../constants/TitlesAndDescriptions';
import {
  col_one,
  col_two,
  col_one_reverse,
  col_two_reverse
} from '../constants/sectionAttributes';
import Section from '../components/Section';
import dizioWaiver from '../assets/images/dizioWaiver.png';
import TitleOfSections from '../components/TitleOfSections';
import dizioReports from '../assets/images/dizioReports.png';
import dizioLoads from '../assets/images/dizioLoadsManager.png';
import dizioBookingsImg from '../assets/images/dizioBookings.png';

export default class About extends Component {
  render() {
    const booking = { ...col_two, imgPath: dizioBookingsImg, imgAlt: 'DizioBookings' };
    const loads = { ...col_two_reverse, imgPath: dizioLoads, imgAlt: 'DizioLoadsManager' };
    const reports = { ...col_two, imgPath: dizioReports, imgAlt: 'DizioReports' };
    const waiver = { ...col_two_reverse, imgPath: dizioWaiver, imgAlt: 'DizioWaiver' };

    return (
      <div id="about">
      
        <TitleOfSections
          title={AboutTile}
          description={AboutDescription} />
        <div style={{
          paddingTop: '5rem',
          backgroundColor: '#F9FCFF',
          paddingBottom: '2rem'
        }}>
          <Section
            col_one={col_one}
            col_two={booking}
            heading={DizioBookings}
            subHeading={DizioBookingsDescription}
          />
          <Section
            col_two={loads}
            col_one={col_one_reverse}
            heading={DizioLoadsManager}
            subHeading={DizioLoadsManagerDescription}
          />
          <Section
            col_two={reports}
            heading={DizioReports}
            col_one={col_one}
            subHeading={DizioReportsDescription}
          />
          <Section
            col_two={waiver}
            heading={DizioWaiver}
            col_one={col_one_reverse}
            subHeading={DizioWaiverDescription}
          />
        </div>

      </div>
    )
  }
}
