import React, { Component } from 'react'
import { Container, Col, Row } from 'reactstrap';

import ContactForm from '../components/ContactForm';

export default class Contact extends Component {
  render() {
    return (
      <div id="contact">
       <div style={{height:'9rem',backgroundColor:'#F9FCFF'}}></div>
        <Container style={{paddingBottom: '5.125rem'}}>
          <Row>
            <Col
              sm={{ size: 12 }}
              className='d-flex justify-content-center' >
              <div className='contact'>
                <ContactForm />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
