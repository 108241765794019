import React, { Component } from 'react'
import { Container, Col, Row } from 'reactstrap';
import ReactPlayer from 'react-player';

import { VideoLink } from '../constants/Link';

export default class VideoPlayer extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col className="d-flex justify-content-center player-wrapper">
            <ReactPlayer
              controls
              width='85%'
              height='85%'
              style={{ 
                position: 'absolute',
                left: 'auto'}}
              url={VideoLink}
              playing={false}
              className='react-player'
            />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center div-delow-video">
            <p className="contact-link">Contact <a className="contact-link-a" href='JavaScript:void(0)'>ha@mergingseas.com</a> for more information and free trial</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

