import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconButton = ({ iconOptions }) => {
    return (
        <Button className="iconButton">
            <FontAwesomeIcon
                icon={[iconOptions.iconType, iconOptions.iconName]}
                color='#001833'
                size="2x" />
        </Button>
    )
}

export default IconButton
