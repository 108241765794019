import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';

const TitleOfSections = (props) => {
    return (
        <Fragment>
            {(props && !props.vid) && <div style={{paddingTop: '1em'}}></div> }
            <Container className={`${(props && props.vid) && 'margin-10'}`}>
                <Row>
                    <Col sm={{ size: 12 }}
                        className={`d-flex flex-column text-center justify-content-center my-5 ${(props && props.vid) && 'video-title'}`}>
                        <h3 className="about-title">{props.title}</h3>
                        <Col md={{ size: 8, offset: 2 }}>
                            <h5 className='about-title-text'>{props.description}</h5>
                        </Col>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}

export default TitleOfSections;

